/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cmsUtils';
import { Item } from '../../../../cms/items/item';
import utils from '../../../../utils';

export function Panel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);
  //console.log(cmsUtils.hasItemInPageDataByItemTypeCode(cmsOption.pageData, 'DestinationOperators'))

  const bgColor = cmsUtils.payload(item, 'BgColor');
  //const textColorTheme = cmsUtils.payload(item, 'TextColorTheme');
  const imageUrl = cmsUtils.payload(item, 'ImageUrl');
  const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
  const bgTheme = cmsUtils.payload(item, 'TextColorTheme') || 'white';
  const vLine = cmsUtils.payload(item, 'VLine') || 'none';
  
  console.log(vLine);

  let bgUrl = null;
  if (imageUrl)
    bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

  const cssClass = utils.classNames((vLine === 'both' || vLine === 'bottom') ? "v_ling_bottom" : "", 'cms_item', 'contentPanel', 'contentPanel--' + bgTheme, item.cssClass || item.anchorName || '');

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  return (
    <section className={cssClass} style={{
      backgroundColor: `${bgColor}`,
      backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl)
    }} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      
      {
        (vLine === "top" || vLine === "both") &&
        <div className="top-curve curve" style={{display:'block'}}>
          <svg viewBox="0 0 500 80" preserveAspectRatio="none">
            <path d="M0,80 L0,0 L250,68 L500,0 L500,80 Z" fill="white" />
            <path d="M0,15 L0,0 L250,68 L250,76 L0,15 Z" fill="#f36c21"/>
            <path d="M500,15 L500,0 L250,68 L250,76 L500,15 Z" fill="black"/>
            <path d="M250,76 L250,68 L300,55 L400,40 Z" fill="#f36c21"/>
            <path d="M250,76 L240,73 L240,66 L250,68 L260,66 L260,73 L250,76 Z" fill="#f36c21"/>
            <path d="M0,0 L500,0 L250,68 L0,0 Z" fill="transparent"/>
          </svg>
        </div>
      }
      <div className="contentPanel__bg"></div>
      <div className="container">{subItemsElts}</div>
      {
        (vLine === "bottom" || vLine === "both") &&
        <div className="bottom-curve curve" style={{display:'block'}}>
          <svg viewBox="0 0 500 80" preserveAspectRatio="none">
            <path d="M0,80 L0,0 L250,68 L500,0 L500,80 Z" fill="transparent" />
            <path d="M0,15 L0,0 L250,68 L250,76 L0,15 Z" fill="transparent"/>
            <path d="M500,15 L500,0 L250,68 L250,76 L500,15 Z" fill="transparent"/>
            <path d="M250,76 L250,68 L300,55 L400,40 Z" fill="transparent"/>
            <path d="M250,76 L240,73 L240,66 L250,68 L260,66 L260,73 L250,76 Z" fill="transparent"/>
            <path d="M0,0 L500,0 L250,68 L0,0 Z" fill="white"/>
          </svg>
        </div>
      }
    </section>
  );
}