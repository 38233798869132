import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: 'black',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: ['80px', null, '107px'],
    marginTop: ['38px', null, '53px'],
    transition: 'all 0.3s ease-in-out'
  })),
  logoScrolled: css(mq({
    img : mq({
      height: [44, null, 56],
      marginTop : '0px'
    })
  })),
}