import { fb, validators } from '../../../lib/form';

export function getContactModel(data) {
  
  const businessList = [
    'Intrastate',
    'Interstate',
    'Local',
    'Courier',
    'Taxi Truck',
    'Linehaul',
    'Warehouse',
    'Parcel',
    'Wharf',
    'Manufacturing',
    'Distribution',
    'Other'
  ].map(x => { return { name: x, value: x } })
  
  const model = fb.group({
    name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
    phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    company: [data.company || '', [], { label: 'Company', type: 'text' }],
    position: [data.position || '', [], { label: 'Position', type: 'text' }],
    businesses: [data.business || [], [], { label: 'Businesses', type: 'checkboxList', options:businessList, style:{inline:true}}],

    headOfficeLocation: [data.headOfficeLocation || '', [], { label: 'Head Office Location', type: 'text' }],
    existingSystems: [data.existingSystems || '', [], { label: 'Existing Systems', type: 'textarea' }],
    
    enquiry: [data.enquiry || '', [], { label: 'Your Message', type: 'textarea' }],
    attachments: [data.attachments || '', [], { label: 'Attachments', type: 'file' }]
  }); 
  return model;
}